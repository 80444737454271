<template>
  <div class="page">
    <!--pages/mycoupons/mycoupons.wxml-->
    <div class="list">
		<div class="empty-box">
		    <div class="desc1">暂无可用优惠券</div>
		</div>
      <!-- <coupon1></coupon1>
      <coupon1></coupon1>
      <coupon1></coupon1> -->
    </div>
  </div>
</template>

<script>
  import coupon1 from 'components/coupon1'
  
  export default {
    name: 'mycoupons',
    data() {
      return {
        searchValue: '',
      }
    },
    components: {
      coupon1,
    },
    methods: {
      doa() {
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }
  .list {
    padding: 24px;
  }
  .empty-box .desc1 {
      text-align: center;
      margin:0 auto;
      padding-top: 90px;
      font-size:43px;
      color:#cccccc;
  }

</style>
