<template>
  <!--components/coupon1/coupon1.wxml-->
  <div class="item flexcenter" @click="chooseCoupon">
    <div class="left">
      <div class="jk">仅可购买途够部分商品</div>
      <div class="sj">2020.01.06-2021.02.05</div>
      <div class="jx">仅限在途够小程序端使用</div>
    </div>
    <div class="right">
      <span :class="['spot', 'spot1', {spotwhite: spotwhite}]"></span>
      <span :class="['spot', 'spot1', {spotwhite: spotwhite}]"></span>
      <img v-show="couponIndex === 0" class="chosen" src="~images/icon/chosen.png">
      <div class="r1">
        <span class="r1l">￥</span>50
      </div>
      <div class="r2">满200可用</div>
      <div class="r3">立即使用</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'coupon1',
    props: {
      spotwhite: {
        type: Boolean,
        value: false,
      },
    },
    data() {
      return {
        couponIndex: 0,
      }
    },
    methods: {
      chooseCoupon(event) {
      },
    }
  }
</script>

<style scoped>
  /* components/coupon1/coupon1.wxss */
  .full-img {
    display: block;
    width: 100%;
  }
  .flexcenter {
    display: flex;
    align-items: center;
  }
  
  /*优惠券列表新样式*/
  .coupon-box .list .list-inner-new {
    height: 300px;
  }
  .item{
    margin-bottom: 24px;
    background-color: #FFFFFF;
  }
  .item .left {
    flex: 1;
    height: 220px;
    box-sizing: border-box;
    padding: 24px;
    border: 1px solid #F5F5F5;
    border-radius: 20px 0 0 20px;
  }
  .item .left .jk{
    margin-bottom: 48px;
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }
  .item .left .sj{
    margin-bottom: 20px;
    font-size: 24px;
    color: #191919;
  }
  .item .left .jx {
    font-size: 24px;
    color: #777777;
  }
  .item .right {
    position: relative;
    box-sizing: border-box;
    width: 220px;
    height: 220px;
    border-radius: 0 20px 20px 0;
    text-align: center;
    background-image: linear-gradient(145deg, #F0D8A2 0%, #DDAF69 100%);
    color: #FFFFFF;
  }
  .item .right .spot {
    position: absolute;
    left: -10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .item .right .spotwhite {
    background-color: #FFFFFF;
  }
  .item .right .spot1{
    top: -10px;
  }
  .item .right .spot2 {
    bottom: -10px;
  }
  .item .right .chosen {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 64px;
  }
  .item .right .r1 {
    height: 100px;
    font-size: 72px;
    font-weight: bold;
    font-family: "Helvetica Neue";
  }
  .item .right .r1 .r1l{
    margin-right: 4px;
    font-size: 32px;
    font-family: "PingFang SC";
  }
  .item .right .r2 {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: bold;
  }
  .item .right .r3 {
    margin: 0 auto;
    width: 140px;
    height: 46px;
    line-height: 46px;
    background-color: #FFFFFF;
    border-radius: 23px;
    color: #DDAF69;
    font-size: 24px;
  }
</style>
